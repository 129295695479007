<template>

</template>

<script>
export default {
  name: 'Home',

  methods: {

  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: .64rem;
  text-align: center;
}
ul {
  li {
    padding: .5333rem;
    color: green;
  }
}
</style>
