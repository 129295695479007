<template>
  <div class="content">

      <h1 class="logo">
          <img src="./images/logo.png" alt="">
      </h1>

      <div class="btn">
          <button>登录授权</button>
      </div>

  </div>
</template>

<script>
export default {
    name: "index"
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  margin: 0 auto;
  position: relative;

  .logo {
    margin: 0 auto;
    margin-top: 3.125rem;
    text-align: center;

    img {
      width: 7.5rem;
    }
  }

  .btn {
    margin-top: 1.875rem;
    text-align: center;
    button {
      width: 6.85rem;
      border-radius: .3125rem;
      height: 1.875rem;
      font-size: .8125rem;
      margin: 0 auto;
      border: none;
      background: #1989fa;
      color: #ffffff;
    }
  }

}
</style>
